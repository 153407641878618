<template>
  <Drag v-if="draggable" :transfer-data="{ letter: letter, value: value }">
    <AudioPlayer v-if="playTextAudioOnClick" :speechText="letter" :settings="audioSettings" hideButton="true" hideButtonName="true" hideSettings="true" ref="audioPlayer"/>
    <v-btn
      v-bind:class="getResolvedClasses"
      color="white"
      :id="letter"
      :disabled="disabled"
      v-bind:draggable="true"
      @click="clicked()"
      v-ripple
    >
      <v-icon
        left
        large
        dark
        :color="!this.assertExpectation && this.disabled ? 'yellow' : 'white'"
        v-if="icon"
        >{{
          !this.assertExpectation && this.disabled ? 'mdi-bullseye' : icon
        }}</v-icon
      >{{ showTranslatedOptions ? $t(letter) : letter }}
    </v-btn>
  </Drag>
  <v-btn
    v-else
    class="keyboard-row-letter"
    :id="letter"
    :disabled="disabled"
    @click="clicked()"
    v-ripple
  >
    {{ letter }}
  </v-btn>
</template>
<script>
import { Drag } from 'vue-drag-drop'
import AudioPlayer from './AudioPlayer'

export default {
  components: {
    Drag,
    AudioPlayer
  },
  props: [
    'letter',
    'gameOver',
    'draggable',
    'disableUponClick',
    'value',
    'expected',
    'assertExpectation',
    'icon',
    'kahootStyle',
    'showTranslatedOptions',
    'audioSettings',
    'playTextAudioOnClick'
  ],
  data: function () {
    return {
      disabled: false
    }
  },
  computed: {
    /**
     * Classes for three different states of the button once expectation is asserted
     * selected_correct - highlight in green
     * selected_incorrect - highlight in red
     * notSelected_correct - highlight in red
     */
    getResolvedClasses() {
      return {
        'keyboard-row-letter': true,
        assertExpectation: this.assertExpectation,
        kahootStyleDisabled: this.kahootStyle && this.disabled,
        correct: this.expected,
        incorrect:
          (!this.kahootStyle && !this.expected && this.disabled) ||
          (this.kahootStyle && !this.expected)
      }
    }
  },
  // disable button on click, and send 'check' event to run check() in main vue instance
  methods: {
    clicked: function () {
      if (this.playTextAudioOnClick) {
        this.$refs.audioPlayer.playOrPause()
      }
      if (this.disableUponClick) {
        this.disabled = true
        this.$emit('check')
      }
    }
  },
  watch: {
    // disable all buttons on game over; re-enable all buttons on restart
    gameOver: function (newValue) {
      this.disabled = newValue
    }
  }
}
</script>
<style scoped>
.v-btn.v-size--default {
  font-size: 1.2rem;
}
button {
  background-color: white;
  border: none;
  color: #333;
  box-shadow: 0 2px 5px #d0d0d0;
  width: auto;
  padding: 5px;
  font-size: x-large;
}
button:disabled {
  border: solid 2px var(--v-primary-base);
}
button .kahootStyleDisabled {
  border: 2px;
}
button.assertExpectation.correct, .theme--light.v-btn.v-btn--disabled.v-btn--has-bg.assertExpectation.correct {
  background-color: green !important;
  color: white !important;
}
button.assertExpectation.incorrect, .theme--light.v-btn.v-btn--disabled.v-btn--has-bg.assertExpectation.incorrect {
  background-color: red !important;
  color: white !important;
}
</style>
